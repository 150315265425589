import { useUserStore } from '../stores/useUserStore'

interface LoginData {
  email: string
  password: string
  code?: string
}

export function useEmailLogin(
  requestBody: Ref<LoginData> | ComputedRef<LoginData>,
) {
  const { getToken, recaptchaQueryParam } = useRecaptcha()
  const {
    execute: login,
    error: loginError,
    data: userData,
  } = useStFetch('/auth/login/email', {
    method: 'post',
    body: requestBody,
    immediate: false,
    watch: false,
    query: recaptchaQueryParam,
  })

  const userStore = useUserStore()
  const { isManualLogin } = storeToRefs(userStore)
  const { handleLogin } = userStore

  async function handleUserLogin() {
    await getToken('/auth/login/email')
    await login()
    if (!userData.value) return
    isManualLogin.value = true
    await handleLogin(userData.value)
  }

  return {
    handleUserLogin,
    loginError,
    userData,
  }
}
