<template>
  <form
    class="form"
    :class="{ mobile: isMobile }"
    @submit.prevent="handleSubmit"
  >
    <StDrawerHeader
      v-if="isMobile"
      class="first-step-title"
      :title="t('authorization.firstStepTitle')"
    />
    <h2 v-else class="title">
      {{ t('authorization.firstStepTitle') }}
    </h2>

    <div class="providers-auth">
      <StButton
        class="provider-button"
        type="gray"
        :label="googleBtnText"
        :size="isMobile ? 'l' : 'xl'"
        icon="c-google"
        is-left-icon
        data-t="google-sign-in"
        @click="handleGoogleSignIn"
      />
      <StButton
        v-if="isTelegramMiniApp"
        class="provider-button"
        type="gray"
        :label="telegramBtnText"
        :size="isMobile ? 'l' : 'xl'"
        icon="c-telegram"
        is-left-icon
        data-t="telegram-sign-in"
        :loading="isTgProcessing"
        @click="handleTelegramMiniAppSignIn"
      />
      <TelegramAuthButton
        v-else
        v-slot="{ status }"
        :auth-params="{ language: locale }"
        @success="handleTelegramLoginWidgetSuccess"
        @error="showTelegramToastErrorByCode"
      >
        <StButton
          class="provider-button"
          type="gray"
          :label="telegramBtnText"
          :size="isMobile ? 'l' : 'xl'"
          icon="c-telegram"
          is-left-icon
          data-t="telegram-sign-in"
          :loading="status === 'loading'"
        />
      </TelegramAuthButton>
    </div>
    <div class="inputs-wrapper">
      <div class="button-separator">
        <span class="line-separator" />
        <span>{{ t('registration.or') }}</span>
        <span class="line-separator" />
      </div>
      <StInput
        v-bind="email.componentBindings"
        :error="!!password.error"
        :label="t('authorization.emailLabel')"
        :placeholder="t('authorization.emailPlaceholder')"
        :size="isMobile ? 'm' : 'l'"
        autocomplete="email"
        type="email"
        data-t="email"
      />
      <div class="password">
        <StInputPassword
          v-bind="password.componentBindings"
          :label="t('authorization.passwordLabel')"
          :placeholder="t('authorization.passwordPlaceholder')"
          type="password"
          data-t="password"
          :error="!!password.error"
          :error-message="password.error"
          :size="isMobile ? 'm' : 'l'"
        />
        <StButton
          class="forgot-password"
          type="text-only"
          data-t="recovery-button"
          size="l"
          replace
          :to="{ query: { modal: 'recoverPassword', email: values.email } }"
          :label="t('authorization.forgotPassword')"
        >
        </StButton>
      </div>
    </div>
    <div class="actions-buttons">
      <StButton
        submit
        data-t="next-step"
        class="submit-button"
        :label="t('authorization.firstStepButton')"
        :size="isMobile ? 'l' : 'xl'"
        :loading="requestStatus === 'pending'"
        :disabled="!isValid || isLoadingRecaptcha"
      />
    </div>
    <div class="create-account">
      <span class="text"> {{ t('authorization.noAccount') }} </span>
      <StButton
        type="text-only"
        class="button"
        replace
        :label="t('authorization.createAccount')"
        data-t="create-account-btn"
        :to="{ query: { modal: 'register' } }"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
import { required, useForm, validEmail } from '@st/validate'
import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import { useTelegram } from '@st/telegram/composables/useTelegram'
import { useEmailLogin } from '../../../composables/useEmailLogin'
import { useGoogleRedirect } from '../../../composables/useGoogleRedirect'
import { useTelegramLoginErrors } from '../../../composables/useTelegramLoginErrors'
import { useUserStore } from '../../../stores/useUserStore'

const { isMobile } = usePlatform()

const emit = defineEmits<{
  success: [payload: { email: string; password: string }]
  login: []
}>()

const { t, locale } = useI18n()
const isCodeSend = ref(false)
const {
  fields: { email, password },
  isValid,
  values,
} = useForm({
  fieldsSchema: {
    email: {
      initialValue: '',
      validators: [
        {
          rule: required,
          triggerErrorDisplay: 'never',
        },
        {
          rule: validEmail,
          errorMessage: t('authorization.emailShouldBeValid'),
        },
      ],
    },
    password: {
      initialValue: '',
      validators: [
        {
          rule: required,
          triggerErrorDisplay: 'never',
        },
      ],
    },
  },
})

const { getToken, isLoadingRecaptcha, recaptchaQueryParam } = useRecaptcha()

const {
  execute: sendCode,
  error,
  status: requestStatus,
} = useStFetch('/auth/login/email/code/send', {
  method: 'post',
  body: values,
  immediate: false,
  watch: false,
  query: recaptchaQueryParam,
})

const { open } = useToast()

const { loginError, handleUserLogin } = useEmailLogin(values)
const { settings } = storeToRefs(useSettingsStore())
const isEnabledtwoFAOnLogin = computed(
  () => !!settings.value?.twoFAOnLoginEnabled,
)

const errorCodes = computed<Record<string, string>>(() => ({
  RECAPTCHA_IS_REQUIRED: t('authorization.errorMessages.recaptchaRequired'),
  RECAPTCHA_IS_INVALID: t('authorization.errorMessages.recaptchaInvalid'),
  VALIDATION_ERROR: t('authorization.errorMessages.validationError'),
  USER_INVALID_PASSWORD: t('authorization.errorMessages.invalidPassword'),
  AUTH_TIMEOUT_ERROR: t('authorization.errorMessages.timeoutError'),
  CONFIRMATION_CODE_RATE_LIMIT: t(
    'authorization.errorMessages.confirmationCodeRateLimit',
  ),
  RECAPTCHA_REQUEST_ERROR: t(
    'authorization.errorMessages.recaptchaRequestError',
  ),
  CONFIRMATION_CODE_REQUIRED: t(
    'authorization.errorMessages.somethingWentWrong',
  ),
  AUTH_FORBIDDEN: t('authorization.errorMessages.selfExclusionError'),
}))

const { duration: parseDuration } = useDate()

const errorMessage = computed(() => {
  const errorBody = error.value?.data || loginError.value?.data

  if (!errorBody) return undefined

  const { error: errorCode } = errorBody

  if (errorCode === 'CONFIRMATION_CODE_RESEND_TIMEOUT' && errorBody.data?.ttl) {
    return t('authorization.errorMessages.confirmationCodeResendTimeout', {
      ttl: parseDuration(errorBody.data.ttl).asSeconds().toFixed(0),
    })
  }

  return (
    errorCodes.value[errorCode] ??
    t('authorization.errorMessages.somethingWentWrong')
  )
})

const googleBtnText = computed(() =>
  isMobile.value
    ? t('registration.googleButtonShortMobile')
    : t('registration.googleButtonShort'),
)

const telegramBtnText = computed(() =>
  isMobile.value
    ? t('registration.telegramButtonMobile')
    : t('registration.telegramButton'),
)

function handleErrorMessage() {
  if (!errorMessage.value) return
  password.setError(' ')
  open({
    label: errorMessage.value,
    type: 'negative',
  })
}

async function handleSubmit() {
  if (isEnabledtwoFAOnLogin.value) {
    if (!isCodeSend.value) {
      await getToken('/auth/login/email/code/send')
      await sendCode()
    }

    if (
      !error.value ||
      error.value?.data?.error === 'CONFIRMATION_CODE_RESEND_TIMEOUT'
    ) {
      isCodeSend.value = true
      emit('success', {
        email: values.value.email,
        password: values.value.password,
      })
    }
  } else {
    await handleUserLogin()
    if (!loginError.value) emit('login')
  }
  handleErrorMessage()
}

watch(
  () => email.value,
  () => password.setError(''),
)

const { handleGoogleRedirect } = useGoogleRedirect()
function handleGoogleSignIn() {
  handleGoogleRedirect({ language: locale.value })
}

const { auth, isTelegramMiniApp } = useTelegram()

const { tryToAuthenticate } = useUserStore()

const { showTelegramToastErrorByCode } = useTelegramLoginErrors()

// логика для telegtam mini app
const isTgProcessing = ref(false)
async function handleTelegramMiniAppSignIn() {
  try {
    isTgProcessing.value = true
    const { error: telegramMiniAppError } = await auth({
      language: locale.value,
    })
    if (telegramMiniAppError) {
      showTelegramToastErrorByCode(telegramMiniAppError.error)
      return
    }

    await tryToAuthenticate()
    emit('login')
  } catch {
    open({
      label: t('authorization.errorMessages.somethingWentWrong'),
      type: 'negative',
    })
  } finally {
    isTgProcessing.value = false
  }
}

async function handleTelegramLoginWidgetSuccess() {
  try {
    await tryToAuthenticate()
    emit('login')
  } catch {
    showTelegramToastErrorByCode()
  }
}
</script>

<style scoped>
.title {
  margin: 0;
  font: var(--desktop-text-xl-semibold);
}

.first-step-title {
  margin-right: calc(var(--spacing-200) * -1);
  margin-left: calc(var(--spacing-200) * -1);
}

.header-text {
  margin: 0;
  padding: var(--spacing-100) 0;
  font: var(--mobile-title-2-semibold);
}

.error-message {
  font: var(--desktop-text-sm-medium);
  color: var(--system-error);
}

.password {
  position: relative;
  margin-bottom: var(--spacing-100);
}

.forgot-password {
  cursor: pointer;

  position: absolute;
  top: 0;
  right: 0;

  padding-top: var(--spacing-050);

  font: var(--desktop-text-xs-medium);
  color: var(--palette-brand-500);

  background-color: transparent;
  border: 0;
}

.create-account {
  display: flex;
  align-items: center;
  justify-content: center;

  .text {
    margin-right: var(--spacing-025);
    font: var(--desktop-text-sm-medium);
    color: var(--text-tertiary);
  }

  .button {
    cursor: pointer;
    background-color: transparent;
    border: 0;
  }
}

.actions-buttons {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-125);
}

.button-separator {
  display: flex;
  gap: var(--spacing-250);
  align-items: center;
  justify-content: center;

  font: var(--desktop-text-xs-medium);
  color: var(--text-secondary);
  text-transform: lowercase;
}

.line-separator {
  flex-grow: 1;
  height: 0.5px;
  background: var(--border-primary);
}

.providers-auth {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-100);

  .provider-button {
    width: 100%;
    min-width: 0;
  }
}

.inputs-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-150);
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-200);

  width: 100%;

  &.mobile {
    gap: var(--spacing-050);
    padding-bottom: var(--spacing-200);

    .title {
      font: var(--mobile-title-2-semibold);
    }

    .submit-button {
      margin-top: auto;
    }

    .providers-auth {
      display: flex;
      flex-direction: column;
      margin-bottom: var(--spacing-100);
    }

    .inputs-wrapper {
      gap: var(--spacing-075);
    }

    .password {
      margin-top: var(--spacing-125);
      margin-bottom: var(--spacing-150);
    }

    .button-separator {
      font: var(--mobile-caption-1-regular);
    }

    .actions-buttons {
      margin-bottom: var(--spacing-150);
    }
  }
}
</style>
